import React, {useContext, useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import 'moment/locale/pt-br';
import {ThemeContext} from '../../apiContexts';
import useNotificationType from '../../hooks/use-notification-type';
import EventList from '../../views/event';
import styled from 'styled-components';
import {Nav, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import {Scrollbars} from 'react-custom-scrollbars';
import {off, on} from '../../helpers/events';
import RoundButton from '../../components/roundButton';
import ReactSelect from '../../components/react-select';
import SinisterEventList from '../../views/sinister/sinister-events';
import './event-container.sass';

const EventContent = styled.div`
  margin-top: -23px;
  // margin-bottom: -16px !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 350px;
`;

const NavLinkStyled = styled(NavLink)`
    padding: 0.5rem  0.5rem 0 1rem;
    a.nav-link {
        color: #6B6B6B !important;
    }
    a.active.nav-link {
        color: #15A6DA !important;
    }
   .active {
     background-color: white;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
   }
`;

const EventFilter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  > div {
    display: flex;
    align-items: center;
  }
  > h5 {
    color: ${(props) => props.theme};
    text-align: left;
    font-weight: bold;
  }
`;

const NavStyled = styled(Nav)`
   border-bottom: 0;
`;

const ButtonsStyle = styled.div`
  button {
    height: 2.7rem;
    width: 2.7rem;
  }
`;

const ReactSelectStyled = styled(ReactSelect)`
  .select {
    border-radius: 9px !important;
  }
`;

const EventContainer = (props) => {
    const theme = useContext(ThemeContext);
    const {types} = useNotificationType();
    const [activeTab, setActiveTab] = useState('1');

    const [eventFilters, setEventFilters] = useState([]);
    const [sinisterFilters, setSinisterFilters] = useState([]);

    const changeSinisterTab = React.useCallback(() => setActiveTab('2'), []);
    const changeEventTab = React.useCallback(() => setActiveTab('1'), []);

    useEffect(() => {
        on('openSinister:click', changeSinisterTab);

        return () => {
            off('openSinister:click', changeSinisterTab);
        };
    }, [changeSinisterTab]);

    useEffect(() => {
        on('openEventTab:click', changeEventTab);

        return () => {
            off('openEventTab:click', changeEventTab);
        };
    }, [changeEventTab]);

    const enableDisableSound = () => {
        const enabled = props.events.getEnabledSound();
        props.events.setEnableSound(!enabled);
    };

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    console.log('props.sinisterStore.list.length'); //TODO dont remove it
    console.log(props.sinisterStore.list.length);

    return (
        <EventContent id="eventList" className="pr-3">
            <div className="" style={{marginTop: '8px', zIndex: 10}}>
                <NavStyled tabs>
                    <NavLinkStyled>
                        <NavLink
                            onClick={() => toggle('1')}
                            className={classnames({active: activeTab === '1'})}>
                            Eventos
                        </NavLink>
                    </NavLinkStyled>
                    <NavLinkStyled>
                        <NavLink
                            onClick={() => toggle('2')}
                            className={classnames({active: activeTab === '2'})}>
                            Sinistro
                        </NavLink>
                    </NavLinkStyled>
                </NavStyled>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <EventFilter theme={props.theme}>
                            <h5 className="mt-2 text-left">Filtros</h5>
                            <ButtonsStyle>
                                <ReactSelectStyled
                                    options={types}
                                    name="typeDevice"
                                    scoped={false}
                                    noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                                    placeholder="Tipo"
                                    className="w-100"
                                    menuPlacement="bottom"
                                    multiple={true}
                                    filters={eventFilters}
                                    onChange={(filters) => setEventFilters(filters ? filters.map(({id}) => id) : [])}
                                />
                                <RoundButton className="mx-2"
                                             onClick={props.events.enableDisableSound}
                                             active={props.events.isSoundEnabled}>
                                    <span className="fal fa-bell"/>
                                </RoundButton>
                                <RoundButton onClick={props.events.removeAll}>
                                    <span className="fal fa-trash-alt"/>
                                </RoundButton>
                            </ButtonsStyle>
                        </EventFilter>
                    </TabPane>
                    <TabPane tabId="2">
                        <EventFilter theme={props.theme}>
                            <h5 className="mt-2 text-left">Sinistros</h5>
                            <ButtonsStyle>
                                <ReactSelectStyled
                                    options={types}
                                    name="typeDevice"
                                    scoped={false}
                                    noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                                    placeholder="Tipo"
                                    className="w-100"
                                    menuPlacement="bottom"
                                    multiple={true}
                                    filters={eventFilters}
                                    onChange={(filters) => setSinisterFilters(filters ? filters.map(({id}) => id) : [])}
                                />
                            </ButtonsStyle>
                        </EventFilter>
                    </TabPane>
                </TabContent>
            </div>
            <Scrollbars style={{height: '100%'}} hideTracksWhenNotNeeded={true}>
                {activeTab === '1' ?
                    <EventList
                        theme={theme}
                        eventTypes={types}
                        removeAll={props.events.removeAll}
                        events={props.events.toList}
                        enableDisableSound={enableDisableSound}
                        hasFilter={eventFilters.length}
                        filters={eventFilters}
                        isSoundEnabled={props.events.getEnabledSound()}/> :
                    <SinisterEventList
                        theme={theme}
                        incidents={props.sinisterStore.list}
                        loadIncidents={() => props.sinisterStore.fetch()}
                        hasFilter={sinisterFilters.length}
                        filters={eventFilters}
                        openingSinister={props.sinisterStore.getOpeningSinister}
                    />}
            </Scrollbars>
        </EventContent>
    );
};

const mapping = ({store}) => ({
    events: store.events,
    devices: store.devices,
    sinisterStore: store.sinisterStore,
});

export default inject(mapping)(observer(EventContainer));

