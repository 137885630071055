import {Howl, Howler} from 'howler';

// play tracks with no audio overlap
const AudioPlayer = (() => {
    let instance = null;

    class _AudioPlayer {
        _tracks = [];
    
        constructor() {
            Howler.html5PoolSize = 1; // only one sound by track
        }
    
        _findTrackById(trackId) {
            return this._tracks.find(track => track.id === trackId);
        }
    
        addTrack(track) {
            if(!(track instanceof _Track)) {
                throw new Error('track should be instanceof AudioPlayer.Track');
            }
            if(this._findTrackById(track.id)) {
                throw new Error(`track with id ${track.id} already exists!`);
            }
            this._tracks.push(track);
        }
    
        play(trackId) {
            if(this.isPlaying) return;
            const track = this._findTrackById(trackId);
            if(!track) return;
            track.play();
        }

        get isPlaying() {
            return this._tracks.some(track => track.isPlaying);
        }
    }
    
    class _Track {
        _id;
        _source;
        _player;
    
        _isLoaded = false;
        _isPlaying = false;
    
        constructor(id, source) {
            this._id = id;
            this._source = source;
            this._player = new Howl({
                src: source,
                pool: 1, // only one sound by track
                onload: (_) => {
                    this._isLoaded = true;
                },
                onloaderror: (_, error) => {
                    console.log(this._id, '__loaderror: ', error);
                },
                onplay: (_) => {
                    this._isPlaying = true;
                },
                onplayerror: (_, error) => {
                    console.log(this._id, '__playerror: ', error);
                },
                onend: (_) => {
                    this._isPlaying = false;
                },
            });
        }
    
        get id() {
            return this._id;
        }
    
        get source() {
            return this._source;
        }
    
        get isLoaded() {
            return this._isLoaded;
        }
    
        get isPlaying() {
            return this._isPlaying;
        }
    
        play() {
            if(!this._isLoaded) return;
            if(this._isPlaying) return;
            this._player.play();
        }
    }

    return {
        getInstance() {
            if(instance === null) {
                instance = new _AudioPlayer();
            }
            return instance;
        },
        Track: _Track
    }
})();

export default AudioPlayer;
