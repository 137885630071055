import React, {useRef} from 'react';
import {observer} from 'mobx-react';
import {createContext, useEffect} from 'react';
import useSocket from 'src/hooks/use-socket.js';
import useStore from 'src/hooks/use-store.js';
import useTimezone from 'src/hooks/use-timezone.js';
import momentTimezone from 'moment-timezone';
import {of} from 'rxjs';
import {mergeAll, mergeMap} from 'rxjs/operators';
import AudioPlayer from 'src/infra/AudioPlayer.js';

export const EventsContext = createContext({});

const EventsProvider = ({children}) => {
    const audioPlayer = useRef(AudioPlayer.getInstance()).current;
    const {timezone} = useTimezone();
    const {events: eventsSocket} = useSocket();
    const {store} = useStore();
    const {auth, events, devices} = store;

    function onAddEvent() {
        if (events.isSoundEnabled) {
            audioPlayer.play('siren');
        }
    }

    useEffect(() => {
        let sub;

        if (auth.isLogged) {
            sub = eventsSocket
                .pipe(
                    mergeAll(),
                    mergeMap(
                        ({deviceId}) => of(devices.findById(deviceId)),
                        ({serverTime, ...e}, d) => ({
                            name: d ? d.name : '',
                            serverTime: momentTimezone(serverTime)
                                .tz(timezone)
                                .format(),
                            ...e,
                        }),
                    ),
                )
                .subscribe((event) => {
                    events.add(event);
                    onAddEvent();
                });
        }

        return () => {
            if (sub && sub.unsubscribe) {
                sub.unsubscribe();
            }
        };
    }, [auth.isLogged]);

    return (
        <EventsContext.Provider value={{}}>
            {children}
        </EventsContext.Provider>
    );
};

export default observer(EventsProvider);
