import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Link} from 'react-router-dom';
import I18nMessage from '../../components/I18nMessage';
import './event.sass';

const colors = {
    green: '#00b105',
    red: '#f2392d',
};

const Card = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border: ${`1px solid ${colors.red}`};
    box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.4);
  `;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;
    span {
      color: ${colors.red};
      font-weight: bold;
      font-size: 0.8rem;
    }
    small {
    }
  `;

const Span = styled.span`
    color: ${colors.red};
    font-size: 1.5rem;
  `;

const Date = styled.span`
    color: ${colors.red};
    font-weight: 600;
    font-size: 0.8rem;
  `;

const LinkStyled = styled(Link)`
    color: inherit !important;
    text-decoration: inherit !important;
  `;


const getEventI18N = (type, attributes) => { // TODO should use from helper
    return attributes.alarm ?
        `alarm${String(attributes.alarm)
            .charAt(0)
            .toUpperCase()}${String(attributes.alarm).slice(1)}` :
        `event${String(type)
            .charAt(0)
            .toUpperCase()}${String(type).slice(1)}`;
};

const EventList = (props) => (
    <div className="p-1 py-2">
        {props.events.filter(({type}) => (!props.hasFilter ? true : props.filters.includes(type))).map((event, i) => (
            <LinkStyled key={event.id} to={`/admin/events/${event.id}`}>
                <Card className="mb-2 p-3">
                    <Description>
                        <span>{event.name}</span>
                        <small>
                            <I18nMessage id={getEventI18N(event.type, event.attributes)}/>
                        </small>
                    </Description>
                    <Date className="mr-1 ml-1">{moment(event.serverTime).fromNow()}</Date>
                    <Span className="fas fa-map-marker-alt ml-2"/>
                </Card>
            </LinkStyled>
        ))}
    </div>
);

export default EventList;
