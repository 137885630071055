import {observer} from 'mobx-react';
import 'moment/locale/pt-br';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {MapContext, ThemeContext} from '../../apiContexts';
import {useMap} from '../../components/map/hooks';
import useMapAlarm from '../../components/map/hooks/useMapAlarm';
import useMapDevice from '../../components/map/hooks/useMapDevice';
import useMapGeofences from '../../components/map/hooks/useMapGeofences';
import useMapLabel from '../../components/map/hooks/useMapLabel';
import useMapPosition from '../../components/map/hooks/useMapPosition';
import useMapReport from '../../components/map/hooks/useMapReport';
import useMapTrace from '../../components/map/hooks/useMapTrace';
import {initSearchData} from '../../components/search-box';
import config from '../../config';
import useStore from '../../hooks/use-store';
import history from '../../routes/history';
import {logOut} from '../../services/authService';
import MapRightActions from '../../views/map-right-actions';
import CenterActions from '../../views/personal/bottom/center-actions';
import '../../views/personal/client.sass';
import {BottomStyle, DevicesStyle} from '../../views/personal/styles';
import Top from '../../views/personal/top';
import FloatingReport from '../../views/reports/floating-report';
import '../../views/personal/device/device.sass';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import DeviceDetail from '../../views/personal/device/device-detail';
import DeviceList from '../../views/personal/device/device-list';
import useAlerts from '../../hooks/use-alerts';
import useServices from '../../hooks/use-services';
import useGeofences from '../../hooks/use-geofence';

const Map = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const PersonalContainer = (props) => {
    const {
        location,
    } = props;

    const mapRef = useRef();
    const {
        selectedItem,
        data,
        setSelectedRow,
        selectedRow,
        setProps,
    } = useContext(MapContext);
    const {toast} = useAlerts();
    const theme = useContext(ThemeContext);
    const {positionService, driverService, commandService} = useServices();
    const {createGeofenceFromCurrentPosition} = useGeofences();
    const {store} = useStore();
    const {server, auth, events, drivers, devices} = store;
    /**
     * Load devices
     */
    // const {devices} = useDevices();

    const intl = useIntl();

    const [isOpenModalCommand, toggleModalCommand] = useState(false);
    const [commandData, setCommandData] = useState({});
    const [report, setReport] = useState();

    const query = useMemo(
        () => ({search: location.search, hash: location.hash}),
        [location.search],
    );

    const [searchData, setSearchData] = useState(initSearchData);

    const {
        instance,
        trace,
        state,
        setTrace,
        toogleTrace: traceToggleCurrentDevice,
        label,
        setLabel,
        selectedMap,
        setSelectedMap,
        setReportData,
        showDevices,
        setShowDevices,
        showGeofences,
        setShowGeofences,
        follow,
        setFollow,
        isAlarmsVisible,
        toggleAlarmsVisibility,
    } = useMap(
        {
            target: mapRef,
            center: [0, 0],
            zoom: 4,
            tiers: config.mapConfig,
            initAllDevices: true,
            centerOnCluster: true,
            cluster: {
                distance: 25,
            },
        },
        useMapPosition,
        useMapDevice,
        useMapTrace,
        useMapLabel,
        useMapReport,
        useMapGeofences,
        useMapAlarm,
    );

    useEffect(() => {
        if (query.search) {
            setReport(query.hash.slice(1));
        } else {
            setReport(null);
        }
    }, [query]);

    useEffect(() => {
        let ignore = false;
        driverService.list().then((drivers) => {
            if (!ignore) {
                store.drivers.setDrivers(drivers);
            }
        });

        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        let ignore = false;
        if (!devices.updatedPositions) {
            positionService.list().then((positions) => {
                if (!ignore) {
                    devices.updatePositions([...positions]);
                }
            });
        }

        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        if (instance) {
            if (selectedRow) {
                instance.selectedReportPoint = selectedRow.id;
            }
        }
    }, [selectedRow]);

    useEffect(() => {
        if (instance) {
            if (selectedItem) {
                const {longitude, latitude} = selectedItem;
                if (longitude && latitude) {
                    instance.goTo([longitude, latitude], 18);
                } else {
                    instance.goTo(null, instance._instance.getView().getZoom() - 0.0001);
                }
            }
        }
    }, [selectedItem]);

    useEffect(() => {
        setReportData(data);
    }, [data]);

    useEffect(() => {
        (async () => {
            setSearchData({
                ...searchData,
                options: devices.list.map(({id, uniqueId, name}) => ({
                    id,
                    uniqueId: uniqueId,
                    name,
                })),
            });
        })();
    }, [devices.list]);

    const logoff = () => logOut();

    useEffect(() => {
        if (instance) {
            setProps({map: instance});
            const map = instance.instance;
            instance.setI18n(intl);
            setSelectedMap('osm');
            map.on('click', (event) => {
                const [feature, layer] =
                map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                    return [feature, layer];
                }) || [];

                if (!layer) return;

                const layerName = layer.get('name');
                if (layerName === 'reportLayer') {
                    const featureName = feature.get('name');
                    if (featureName !== 'line') {
                        setSelectedRow({
                            id: feature.get('id') || feature.getId(),
                            scroll: true,
                        });
                    }
                }

                if (layerName === 'devicesLayer') {
                    const features = feature.get('features');
                    if (features.length === 1) devices.setSelected(features[0].getId());
                }
            });

            map.on('pointermove', (event) => {
                map.getTargetElement().style.cursor = map.hasFeatureAtPixel(event.pixel) ?
                    'pointer' :
                    '';
            });

            map.on('pointerdrag', (event) => {
                setFollow(false);
            });

            let zoomCache;
            map.on('movestart', (event) => {
                zoomCache = event.map.getView().getZoom();
            });

            map.on('moveend', (event) => {
                const zoom = event.map.getView().getZoom();
                zoom !== zoomCache && setFollow(false);
            });
        }
    }, [instance]);

    const followToogle = () => {
        const status = !follow;
        setFollow(status);
        if (status) {
            const {longitude, latitude} = devices.selectedDevice.currentPosition;
            instance.goTo([longitude, latitude]);
        }
    };

    const toggleTrace = () => {
        setTrace(prev => !prev);
    };

    const traceToggleCurrent = () => {
        traceToggleCurrentDevice(devices.selectedDevice);
    };

    const toggleLabel = () => {
        setLabel(!label);
    };

    const onChangeMap = (value) => {
        setSelectedMap(value);
    };

    const toggleDevice = () => {
        setShowDevices(!showDevices);
    };

    const toggleGeofences = () => {
        setShowGeofences(!showGeofences);
    };

    const close = () => {
        instance.removeReports();
        history.push('/client');
    };

    const setDeviceSelected = (deviceId) => {
        devices.setSelected(deviceId);
        const {currentPosition} = devices.selectedDevice.currentPosition;
        if (currentPosition) {
            const {longitude, latitude} = devices.selectedDevice.currentPosition;
            instance.goTo([longitude, latitude], 18);
        }
    };

    const searchDevice = useCallback((data) => {
        if (data.length) {
            const {id} = data[0];
            setDeviceSelected(id);
        }
    }, []);

    const selectDevice = (id) => {
        const device = devices.findById(id);
        if (!!device.currentPosition == false) {
            toast.info(intl.formatMessage({id: 'deviceSelectedNeverPositioned'}));
            return;
        }
        devices.setSelected(id);
        const {currentPosition} = devices.selectedDevice;
        if (currentPosition) {
            const {longitude, latitude} = currentPosition;
            instance.goTo([longitude, latitude], 18);
        }
    };

    const block = (status) => {
        const {selectedDevice} = devices;
        const {id} = selectedDevice;
        const data = {deviceId: id, type: status ? 'engineStop' : 'engineResume'};
        setCommandData(data);
        toggleModalCommand(true);
    };

    const sendCommand = async () => {
        await commandService.send(commandData);
        toast.success(intl.formatMessage({id: 'commandSent'}));
        toggleModalCommand(false);
    };

    const unSelectDevice = (_) => devices.setSelected(null);

    return (
        <div id="client">
            <Map ref={mapRef}/>

            <Top
                searchData={{...searchData, onChange: searchDevice}}
                profile={auth.profile}
                logOut={logoff}
                events={events}
                setDeviceSelected={setDeviceSelected}
            />
            <div className="content"/>
            {report && (
                <FloatingReport type={report} location={location} close={close}/>
            )}

            <BottomStyle id="bottom">

                <DevicesStyle id="devices">
                    {devices.selectedDevice ? (
                        <DeviceDetail
                            selectedDevice={devices.selectedDevice}
                            server={server}
                            drivers={drivers}
                            theme={theme}
                            isOpenModalCommand={isOpenModalCommand}
                            toggleModalCommand={toggleModalCommand}
                            sendCommand={sendCommand}
                            commandData={commandData}
                            unSelectDevice={unSelectDevice}/>
                    ) : (
                        <DeviceList devices={devices} onSelect={selectDevice}/>
                    )}
                </DevicesStyle>

                {devices.selectedDevice && (
                    <CenterActions
                        trace={
                            devices.selectedDevice ? devices.selectedDevice.withTrace : false
                        }
                        traceToggle={traceToggleCurrent}
                        followToogle={followToogle}
                        follow={follow}
                        onBlock={block}
                        createGeofence={createGeofenceFromCurrentPosition}
                    />
                )}

                <MapRightActions
                    map={instance}
                    toggleTrace={toggleTrace}
                    trace={trace}
                    toggleLabel={toggleLabel}
                    label={label}
                    onChangeMap={onChangeMap}
                    selectedMap={selectedMap}
                    toggleDevice={toggleDevice}
                    toggleGeofences={toggleGeofences}
                    showGeofences={showGeofences}
                    showDevices={showDevices}
                    isAlarmsVisible={isAlarmsVisible}
                    toggleAlarmsVisibility={toggleAlarmsVisibility}
                />
            </BottomStyle>

        </div>
    );
};

export default observer(PersonalContainer);

PersonalContainer.propTypes = {
    location: PropTypes.any,
};
