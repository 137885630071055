import 'bootstrap/dist/css/bootstrap.css';
import makeInspectable from 'mobx-devtools-mst';
import {Provider} from 'mobx-react';
import {onSnapshot} from 'mobx-state-tree';
// import * as Sentry from '@sentry/browser';
// import { onPatch } from "mobx-state-tree";
import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ReactDOM from 'react-dom';
import {ToastProvider} from 'react-toast-notifications';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {ContextProviderComposer, MapProvider} from './apiContexts';
import AppContainer from './containers/app-container';
import LanguageProvider from './containers/language-container';
import {getStorageSnapshot} from './helpers/auth';
import {getToken} from './helpers/request';
import './index.sass';
import {setSnapshop} from './services/authService';
import * as serviceWorker from './serviceWorker';
import stores from './stores';
import config from './config';
import AudioPlayer from './infra/AudioPlayer.js';
import EventsProvider from './providers/events';

// Sentry.init({3
//   environment: config.environment,
//   dsn: 'https://fc8f4a0b0a8840e9afdbde50204dcfde@sentry.azultecnologia.com/6',
// });

const initialState = getToken() ? getStorageSnapshot() : {};

const store = stores.create(initialState);

onSnapshot(store, ({session, auth, server, events}) =>
  setSnapshop({session, auth, server, events}),
);

const toastConfig = {
  autoDismissTimeout: 5000,
  placement: 'bottom-left',
};

AudioPlayer.getInstance().addTrack(new AudioPlayer.Track('siren', process.env.PUBLIC_URL + '/sound/siren.mp3'));

ReactDOM.render(
    <Provider store={store}>
      <ContextProviderComposer
        contextProviders={[
          <LanguageProvider />,
          <ToastProvider {...toastConfig} />,
          <MapProvider />,
          <EventsProvider />
        ]}>
        <AppContainer />
      </ContextProviderComposer>
    </Provider>,
    document.getElementById('root'),
);

// onPatch(store, patch => console.log(patch));
makeInspectable(store);

serviceWorker.unregister();
